import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import FlexibleContent from "../components/flexible-content";
import Partners from "../components/fc-partners";
import { Helmet } from "react-helmet";
import { cn } from "../lib/helpers";
import PageHeader from "../components/product-page-header";

export const query = graphql`

fragment Si on SanityImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query pricingPageQuery {
    pricing: sanityPricing {
      showPartnersAndClients,
      showTestimonials,
      flexibleContent {
        ... on SanityProductPageHeader {
          _type
          title
          bg {
            ...Si
          }
        }
        ... on SanityFcPricing {
          _key
          _type
          title
          purewebRealityText
          purewebExtendedRealityText
          subtitle
          disclaimer
          pricingPlans {
            pricing {
              category
              name
              price
              description
              features {
                name
                featureList
              }
              callToAction {
                title
                _rawDescription
                button {
                  blank
                  secondaryButton
                  text
                  url
                }
              }
            }
          }
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
    product: sanityProduct {
      partners {
        title
        partners {
          _key
          partner {
            name
            slug {
              current
            }
            logo {
              alt
              asset {
                extension
                url
                _id
              }
            }
          }
        }
      }
    }
    home: sanityHome {
      flexibleContent {
        ... on SanityFcMultiColumnContent {
          _key
          _type
          title
          content {
            _key
            icon {
              asset {
                _id
              }
            }
            title
            _rawContent
            link {
              blank
              text
              url
            }
          }
          themeColorBg
          centerAligned
          alternativeLayout
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const pricing = (data || {}).pricing;
  const showProduct = pricing.showPartnersAndClients;
  const showTestimonials = pricing.showTestimonials;
  const product = (data || {}).product;
  const home = (data || {}).home;
  const title = pricing.seo?.title || "";
  const description = pricing.seo?.description;
  const image = pricing.seo?.thumbnail;
  const autoSiteTitle = !pricing.seo?.title;
  const trimmedDownHome = home.flexibleContent.filter((item) => item.title === 'Testimonials')
  const pageHeaderBlock = pricing.flexibleContent.find(
    (block) => block._type === "productPageHeader"
  );
  return (
    <Layout>
       <Helmet>
        <html className={cn("color-palette")} lang="en" />
      </Helmet>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />
          {pageHeaderBlock?.title && (
        <PageHeader
          title={pageHeaderBlock.title}
          subtitle={pageHeaderBlock.subtitle}
          bg={pageHeaderBlock.bg}
          dynamic={true}
        />
      )}
      {pricing.flexibleContent && (
        <FlexibleContent items={pricing.flexibleContent} />
      )}
       {product.partners && showProduct && (<Partners {...product.partners} />)}
       {home.flexibleContent && showTestimonials  && (<FlexibleContent items={trimmedDownHome} />)}
    </Layout>
  );
};

export default IndexPage;
